import { Box, Modal, Typography, Button } from '@mui/material'
import { Mx8Theme } from '../layout'
import { WarningModalProps } from '../utils/types'
import { FC } from 'react'

const WarningModal: FC<WarningModalProps> = ({
  open,
  onDiscard,
  onSave,
  onWanring,
  description,
  buttonHiding,
}) => {
  return (
    <Modal
      open={open}
      data-testid="warning-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: '600px',
          bgcolor: 'background.paper',
          boxShadow: 'none',
          border: `1px solid ${Mx8Theme.palette.divider}`,
          p: 4,
          borderRadius: '10px',
        }}
      >
        <Typography id="modal-description" sx={{ mt: 2 }}>
          {description}
        </Typography>
        {buttonHiding == 'default-code' ? (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onWanring}
              data-testid="discard-button"
            >
              OK
            </Button>
          </Box>
        ) : (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSave}
              sx={{ mr: 1 }}
              data-testid="save-button"
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onDiscard}
              data-testid="discard-button"
            >
              Discard
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default WarningModal
