import { Mx8Theme } from '../../../../../layout'

export const thumbnailStyle = {
  border: `1px solid ${Mx8Theme.palette.divider}`,
  width: '100px',
}

export const visibilityIconContainer = {
  position: 'absolute',
  bottom: 7,
  left: 80,
  width: '100%',
  height: 20,
}

export const visibilityIconStyle = {
  color: Mx8Theme.palette.primary.main,
  fontSize: 30,
  padding: 2,
  backgroundColor: 'white',
  border: `1px solid ${Mx8Theme.palette.divider}`,
}

export const fileNameStyle = {
  width: '350px',
  fontSize: 14,
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export const textFieldStyle = { display: 'flex', justifyContent: 'center' }
