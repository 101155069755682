import { Box, Button, Typography } from '@mui/material'
import { FC } from 'react'
import {
  questionContentContainer,
  styleForLandscapeMainBox,
  textContent,
  typoStyle,
} from './styles'
import Markdown from 'markdown-to-jsx'
import { ThemeType } from '../../../../../../types'
import { DUMMY_QUESTION } from '../../../../../../constants'
import { actionBtn, chipWrapper, optionContainer } from '../../style'

const Landscape: FC<{ customTheme: ThemeType }> = ({ customTheme }) => {
  return (
    <Box sx={styleForLandscapeMainBox} data-testid="select-input" width="100%">
      <Box
        data-testid="question-content"
        sx={questionContentContainer(customTheme?.secondary_color)}
      >
        <Typography
          variant="body1"
          sx={textContent}
          data-testid="others-rank-sorter-content"
        >
          <Markdown
            options={{
              overrides: {
                img: {
                  props: {
                    className: 'custom-image', // Apply custom Material-UI styles
                  },
                },
              },
              disableParsingRawHTML: true,
            }}
          >
            {DUMMY_QUESTION}
          </Markdown>
        </Typography>
      </Box>
      <Box sx={optionContainer(true)}>
        <Typography
          variant="body1"
          sx={typoStyle(customTheme?.main_color)}
          data-testid="others-rank-sorter-content"
        >
          <Markdown
            options={{
              overrides: {
                img: {
                  props: {
                    className: 'custom-image', // Apply custom Material-UI styles
                  },
                },
              },
              disableParsingRawHTML: true,
            }}
          >
            {customTheme?.hints?.multichoice}
          </Markdown>
        </Typography>
        <Box sx={chipWrapper(true)}>
          <Button
            sx={actionBtn(
              customTheme?.main_color,
              customTheme?.secondary_color,
            )}
          >
            Yes
          </Button>
          <Button
            sx={actionBtn(
              customTheme?.main_color,
              customTheme?.secondary_color,
            )}
          >
            No
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Landscape
