import { SxProps } from '@mui/material'
import { Mx8Theme } from '../../../layout/themes'

export const summaryCard: SxProps = {
  height: '100%',
  width: '100%',
  padding: '16px',
  border: `1px solid ${Mx8Theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'background.paper',
}

export const descriptionBox: SxProps = {
  marginTop: 1.5,
  '& .RaLabeled-label': {
    display: 'none',
  },

  '& .toastui-editor-ww-container>.toastui-editor': {
    backgroundColor: 'background.paper',
  },
  '& .toastui-editor-defaultUI-toolbar': {
    backgroundColor: Mx8Theme.palette.divider,
  },
  '& .toastui-editor-defaultUI-toolbar button': {
    borderColor: Mx8Theme.palette.divider,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  '& .toastui-editor-defaultUI-toolbar button:not(:disabled):hover': {
    backgroundColor: 'background.paper',
    borderColor: 'background.paper',
  },
}
