import { SxProps } from '@mui/system'

export const previewContainer: SxProps = {
  padding: '20px 2%',
  border: '1px solid',
  position: 'relative',
  backgroundColor: 'background.paper',
}

export const previewLabel: SxProps = {
  padding: '0px 10px',
  position: 'absolute',
  top: '-12px',
  left: '20px',
  backgroundColor: 'inherit',
  fontSize: '14px',
}

export const parentContainer = (isLandscapeMode: boolean): SxProps => ({
  maxWidth: isLandscapeMode ? '100%' : '700px',
  minHeight: '100%',
  width: '100%',
  padding: {
    sm: isLandscapeMode ? '0' : '20px',
  },
  margin: '0 auto',
  display: 'flex',
  height: isLandscapeMode ? null : '70vh',
})

export const chatBodyContainer = (
  backgroundImage: string,
  isLandscapeMode: boolean,
): SxProps => ({
  position: 'relative',
  width: '100%',
  padding: isLandscapeMode
    ? {
        md: '20px 50px',
        sm: '20px 15px',
      }
    : null,
  borderWidth: 0.5,
  borderRadius: '20px',
  WebkitBoxShadow: '-1px 4px 16px -8px rgba(0,0,0,0.83)',
  MozBoxShadow: '-1px 4px 16px -8px rgba(0,0,0,0.83)',
  boxShadow: '-1px 4px 16px -8px rgba(0,0,0,0.83)',
  backgroundImage: `url(${backgroundImage})`,
})

export const chatBodyWrapper = (isLandscapeMode: boolean): SxProps => ({
  minHeight: '100%',
  width: '100%',
  height: isLandscapeMode ? null : '100%',
  display: 'flex',
  flexDirection: isLandscapeMode ? 'row' : 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  position: 'relative',
  overflow: isLandscapeMode ? null : 'auto',
})

export const chipWrapper = (isLandscapeMode: boolean): SxProps => ({
  display: 'flex',
  justifyContent: isLandscapeMode ? 'center' : 'flex-end',
  alignItems: 'center',
  flexWrap: 'wrap',
})

export const actionBtn = (color: string, secondary_color: string): SxProps => ({
  padding: { md: '0 30px' },
  border: `2px solid ${color}`,
  fontSize: { md: 18, xs: 12 },
  borderRadius: '8px',
  fontWeight: '400',
  textTransform: 'none',
  margin: 0.5,
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  backgroundColor: secondary_color,
  color: color,
  '&:hover': {
    backgroundColor: secondary_color,
    color: null,
  },
})

export const optionContainer = (isLandscapeMode: boolean): SxProps => ({
  margin: isLandscapeMode ? null : '0 15px 10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: isLandscapeMode ? 'center' : 'flex-end',
  justifyContent: 'center',
})
