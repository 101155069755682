import React, { useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import { ReportingGrid } from '../../../components'
import { ReportTabsProps } from '../types'

// Reusable ReportTabs component
const ReportTabs: React.FC<ReportTabsProps> = ({ tabs, record }) => {
  const [value, setValue] = useState(0) // State to track the selected tab

  // Handle tab change
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map(({ source, label }) => (
          <Tab key={source} label={label} />
        ))}
      </Tabs>
      {tabs.map(
        ({ source }, index) =>
          value === index && (
            <Box key={source} sx={{ p: 1, height: '100%' }}>
              <ReportingGrid
                reportId={record.id}
                source={source}
                ignore_missing={false}
                label=""
                dataset={record.dataset_key}
              />
            </Box>
          ),
      )}
    </Box>
  )
}

export default ReportTabs
