import React, { FC } from 'react'
import { SelectInput } from 'react-admin'
import { ReportDownloadDropdownProps } from '../../types'

const ReportDownloadDropdown: FC<ReportDownloadDropdownProps> = (props) => {
    const {
        setreportFileType,
        choices,
        source,
        id,
        reportDownloadDisabled,
        defaultValue,
    } = props
    return (
        <SelectInput
            id={id}
            disabled={reportDownloadDisabled}
            source={source}
            choices={choices}
            data-testid={id}
            defaultValue={defaultValue}
            /* c8 ignore next 5 */
            onChange={(e) => {
                setreportFileType(e.target.value)
            }}
        />
    )
}

export default ReportDownloadDropdown
