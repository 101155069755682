import { Box, Chip } from '@mui/material'

const TopicsField: React.FC<{ source: string[] }> = (props) => {
  return (
    <Box display="flex" gap="3px">
      {props.source?.map((name: string) => (
        <Chip sx={{ fontSize: 12 }} label={name} key={name} />
      ))}
    </Box>
  )
}

export default TopicsField
