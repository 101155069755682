export const mediaModalContainer = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '80%', md: '55%' },
  bgcolor: 'background.paper',
  borderColor: 'white',
  boxShadow: 24,
  p: 2,
}

export const textContainer = {
  marginBottom: 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'text.primary',
}

export const textStyle = {
  maxWidth: { xs: 200, md: '100%' },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'text.primary',
}

export const closeIconStyle = { cursor: 'pointer', fontSize: 28 }
