export const translationApiCall = async (
  translationId: number,
  setDownloading: (state: boolean) => void,
  setError: (state: boolean) => void,
  token: string,
) => {
  try {
    const apiProtocol = import.meta.env.VITE_MX8_ADMIN_API_PROTOCOL ?? 'https'
    const apiUrl = `${apiProtocol}://${import.meta.env.VITE_MX8_ADMIN_API_DOMAIN}/v1/translations/download/${translationId}`

    const response = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    })

    /* c8 ignore next 3 */
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`)
    }

    // Read response as text
    const text = await response.text()

    const blob = new Blob(
      [Uint8Array.from(atob(text), (char) => char.charCodeAt(0))],
      {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    )

    // Create and trigger download
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `translations_${translationId}.xlsx`
    document.body.appendChild(a)
    a.click()
    a.remove()
    URL.revokeObjectURL(url)
    setDownloading(false)
    setError(false)
    console.log('File downloaded successfully')
  } catch (error) {
    console.error('Error fetching translation:', error)
  }
}
