import { Alert, Box } from '@mui/material'
import { useRecordContext } from 'ra-core'
import { FC } from 'react'

const AudienceError: FC = () => {
  const record = useRecordContext()
  const errors = record?.errors

  /* c8 ignore next */
  if (!record || !errors?.length) return
  return (
    <Box>
      <Alert severity="error">
        {errors?.length} errors in survey code:
        <ul>
          {errors.map((message: string) => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      </Alert>
    </Box>
  )
}

export default AudienceError
