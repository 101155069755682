import { SxProps } from '@mui/material'

export const headerContainer = (color: string): SxProps => ({
  width: 'inherit',
  padding: '10px 20px 10px 20px;',
  borderRadius: '20px 20px 0px 0px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: color,
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
})

export const headerInfo = (secondary_color: string): SxProps => ({
  fontSize: 13,
  color: secondary_color,
  fontWeight: '400',
})

export const headerName = (secondary_color: string): SxProps => ({
  fontSize: 18,
  color: secondary_color,
  fontWeight: '900',
})

export const chatResponseContainer: SxProps = {
  width: '100%',
  height: '100%',
  padding: {
    sm: 2,
    xs: 1,
  },
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  flex: 1,
}

export const commonContainer: SxProps = {
  paddingLeft: '20px',
  paddingRight: '20%',
  display: 'flex',
  margin: '8px 0',
  justifyContent: 'flex-start',
}

export const wrapperStyle = (secondary_color: string): SxProps => ({
  py: 1,
  px: 2,
  position: 'relative',
  boxShadow: '4px 4px 1px rgba(0, 0, 0, 0.3)',
  opacity: '1',
  backgroundColor: secondary_color,
  borderRadius: '10px',
  color: 'text.primary',
})

export const textContent = {
  whiteSpace: 'pre-line',
  lineHeight: 1.3,
}

export const inputContainer = (color: string): SxProps => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderRadius: '0px 0px 20px 20px',
  alignItems: 'center',
  backgroundColor: color,
  padding: '15px 0 15px 15px',
})

export const placeholderContainer = (secondary_color: string): SxProps => ({
  minHeight: '34px',
  fontSize: 16,
  backgroundColor: secondary_color,
  flex: 1,
  borderRadius: '15px',
  paddingTop: '5px',
  paddingBottom: '5px',
  paddingLeft: '10px',
  color: '#7d7d7d',
})
