import { SxProps } from '@mui/system'

export const InsightsCustomStyle: SxProps = {
  padding: 1,
  '& .MuiCard-root': { boxShadow: 'none', important: 'true' },
  '& .MuiToolbar-dense': { minHeight: 0 },
  '& .MuiToolbar-regular': { minHeight: 0 },
  '& .MuiSvgIcon-root': { fontSize: '1.1rem' },

  backgroundColor: 'background.paper',
}
