import { InsightEditor } from '../../Edit'
import { InsightsReport } from '../../Show'
import { InsightsCreateForPass } from '../../Create'
import { NavigatorProps } from '../../../../utils/types'
import { Navigator } from '../../../../components'

export const InsightsNavigator = (props: NavigatorProps) => {
  return (
    <Navigator
      reportResource="insights"
      reportType="insight"
      reportLabel="Insights"
      reportNameField="question"
      customLabel="Create"
      ReportElement={InsightsReport}
      EditElement={InsightEditor}
      CreateElement={InsightsCreateForPass}
      {...props}
    />
  )
}
