import {
  Box,
  Card,
  CardContent,
  Grid2,
  TextField as MUITextField,
  Typography,
  Stack,
  Link,
  Tooltip,
  IconButton,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  FileField,
  FileInput,
  FormDataConsumer,
  Labeled,
  NumberInput,
  required,
  TextInput,
  useRecordContext,
  NumberField,
  ReferenceField,
  maxLength,
  ReferenceInput,
  SelectInput,
  WithRecord,
  useCreate,
  useNotify,
  useGetOne,
  TextField,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { CopyAll, ModeEdit } from '@mui/icons-material'
import { CodeField } from '../../../../components/CodeEditor'
import {
  LanguageAudienceEdit,
  LanguageCountryAudienceInput,
  TypeInput,
} from '../AudienceInputs'
import {
  CountryMap,
  LanguageMap,
  sampleFields,
  templateData,
} from '../../constants'
import { Mx8Theme } from '../../../../layout'
import AudienceStatus from '../AudienceStatus'
import PaymentModal from '../PaymentModal'
import { AudienceCommonInputInterface } from '../../types'

const AudienceCommonInput: React.FC<AudienceCommonInputInterface> = ({
  template,
  setShowTemplateChooser = undefined,
  isCreate = false,
  source = '',
}) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const formValue = useFormContext()

  const record = useRecordContext()

  const notify = useNotify()
  const [create] = useCreate()

  const { data, isLoading, refetch } = useGetOne('audiences', {
    id: record?.id,
  })

  const isEditDisable =
    !(record?.status === 'New' || record?.status === 'Error') && !isCreate

  useEffect(() => {
    if (isCreate) {
      /* c8 ignore next 4 */
      setValue(
        `${sourcePrefix}name`,
        template?.id ? template?.audienceName : 'US Genpop',
      )
    }
  }, [template?.id])
  /* c8 ignore next */
  if (!formValue) return
  const { setValue } = formValue

  const sourcePrefix = source ? `${source}.` : ''

  const handleCopy = () => {
    navigator.clipboard.writeText(data.survey_url)
    notify('URL copied to clipboard.', { type: 'success' })
    refetch()
  }

  /* c8 ignore next 22 */
  const handleCreateDataset = async () => {
    const datasetName = prompt('Enter a new dataset name:')
    try {
      const newDataset = await create(
        'datasets',
        {
          data: {
            name: datasetName,
            survey_id: record?.survey_id,
            project_id: record?.project_id,
          },
        },
        { returnPromise: true },
      )
      return { id: newDataset.id, value: newDataset.name }
    } catch (error) {
      notify('An error occurred while creating the dataset', {
        type: 'error',
      })
      throw error
    }
  }

  /* c8 ignore next 6 */
  const handleClose = () => {
    setOpenPaymentModal(false)
  }

  return (
    <>
      <PaymentModal open={openPaymentModal} handleClose={handleClose} />
      <Grid2
        width="100%"
        container
        spacing={2}
        data-testid="audience-common-input"
      >
        <Grid2 size={{ xs: 12, md: 4.5 }} className="inputfieldStyle">
          <TextInput
            id="audience_name"
            source={`${sourcePrefix}name`}
            fullWidth
            data-testid={'Audience-Name'}
            validate={[required(), maxLength(200)]}
            defaultValue={template?.name}
            autoFocus
            className="white-bg"
            disabled={isEditDisable}
          />
          <NumberInput
            id="target_responses"
            source={`${sourcePrefix}target_responses`}
            fullWidth
            validate={required()}
            defaultValue={300}
            className="white-bg"
            sx={{
              marginTop: 0,
            }}
            disabled={isEditDisable}
          />
          {isCreate ? null : (
            <WithRecord
              render={(record) => (
                <AudienceStatus
                  status={record?.status}
                  setOpenPaymentModal={setOpenPaymentModal}
                />
              )}
            />
          )}

          {isCreate ? (
            <LanguageCountryAudienceInput
              id={'1'}
              source={`${sourcePrefix}language`}
              defaultValue={template?.language}
              options={LanguageMap}
              sx={{
                marginTop: 0,
              }}
            />
          ) : (
            <LanguageAudienceEdit source="language" />
          )}

          {source !== 'audience' && (
            <WithRecord
              render={(record) => (
                <ReferenceInput
                  source={`${sourcePrefix}weighting_audience_id`}
                  reference="audiences"
                  fullWidth
                  label="Weighting Audience"
                  filter={{ survey_id: record?.survey_id }}
                >
                  <SelectInput
                    id="weighting_audience_id"
                    fullWidth
                    className="white-bg"
                    defaultValue={null}
                    emptyText={isCreate ? 'Weighted to self' : 'Not Weighted'}
                    disabled={isEditDisable}
                  />
                </ReferenceInput>
              )}
            />
          )}

          {source !== 'audience' && (
            <WithRecord
              render={(record) => (
                <ReferenceInput
                  source={`${sourcePrefix}dataset_id`}
                  reference="datasets"
                  fullWidth
                  label="Dataset"
                  filter={{ survey_id: record?.survey_id }}
                >
                  <SelectInput
                    id="dataset_id"
                    fullWidth
                    validate={required()}
                    className="white-bg"
                    onCreate={handleCreateDataset}
                    createLabel="...new dataset"
                    disabled={isEditDisable}
                  />
                </ReferenceInput>
              )}
            />
          )}
          {source !== 'audience' && (
            <NumberInput
              source={`${sourcePrefix}weight`}
              label="Weight in Dataset"
              step={0.05}
              fullWidth
              defaultValue={1}
              className="white-bg"
              disabled={isEditDisable}
            />
          )}

          {isCreate ? null : (
            <Card
              data-testid="audience-card"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column' },
                justifyContent: 'space-between',
              }}
            >
              <CardContent
                sx={{
                  backgroundColor: 'background.paper',
                  border: `1px solid ${Mx8Theme.palette.divider}`,
                }}
              >
                <Stack spacing={0}>
                  <Typography variant="body2">Project:</Typography>
                  <ReferenceField
                    source={`${sourcePrefix}project_id`}
                    reference="projects"
                    link={false}
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '17px',
                        fontWeight: 600,
                        important: 'true',
                      },
                    }}
                  />
                  <Typography variant="body2">Survey:</Typography>
                  <ReferenceField
                    source={`${sourcePrefix}survey_id`}
                    reference="surveys"
                    link={false}
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '17px',
                        fontWeight: 600,
                        important: 'true',
                      },
                    }}
                  />
                  <Box
                    sx={{ display: 'flex', gap: '6px', alignItems: 'baseline' }}
                  >
                    <Typography variant="subtitle1">$</Typography>
                    <NumberField
                      source={`${sourcePrefix}survey_units`}
                      sx={{ width: '100%' }}
                      variant="h6"
                      label="$"
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          )}
        </Grid2>

        <Grid2 size={{ xs: 12, md: 7.5 }} className="inputfieldStyle">
          <TypeInput
            source={`${sourcePrefix}type`}
            data-testid="type"
            isCreate={isCreate}
            className="white-bg"
          />
          {isCreate && setShowTemplateChooser && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
                alignItems: 'center',
                marginBottom: 3,
              }}
            >
              <Box
                sx={{
                  flex: 0.9,
                }}
              >
                <MUITextField
                  size="small"
                  label="Template"
                  fullWidth
                  className="white-bg"
                  value={template?.name}
                />
              </Box>
              <Box
                sx={{
                  flex: 0.1,
                }}
              >
                <button
                  style={{
                    border: '1px solid #317CFE',
                    borderRadius: '.2rem',
                    backgroundColor: 'transparent',
                    color: '#317CFE',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '.4rem .8rem',
                    fontSize: '.9rem',
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                  data-testid={`audience-common-back-btn`}
                  onClick={() => {
                    setShowTemplateChooser(true)
                  }}
                >
                  <ModeEdit data-testid={`audience-common-back-btnIcon`} />
                  CHANGE
                </button>
              </Box>
            </Box>
          )}

          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}
          >
            <Grid2 sx={{ flexBasis: { xs: '100%', sm: '50%' } }}>
              <NumberInput
                source={`${sourcePrefix}days_in_field`}
                validate={required()}
                defaultValue={7}
                fullWidth
                className="white-bg"
                sx={{
                  marginTop: isCreate ? '8px' : 0,
                }}
                disabled={isEditDisable}
              />
            </Grid2>
            <Grid2 sx={{ flexBasis: { xs: '100%', sm: '50%' } }}>
              {/* /* c8 ignore next 1 */}
              <LanguageCountryAudienceInput
                id={'2'}
                source={`${sourcePrefix}market`}
                defaultValue={template?.country}
                options={CountryMap}
                sx={{
                  marginTop: isCreate ? '8px' : 0,
                }}
                disabled={isEditDisable}
              />
            </Grid2>
          </Box>
          {/* added panel audience*/}
          <FormDataConsumer>
            {({ formData }) => {
              /* c8 ignore next 6 */
              const audienceType = isCreate
                ? formData?.type || formData?.audience?.type
                : record?.audience?.type || record?.type
              const isBasic = audienceType === 'basic'
              const isPanel = audienceType === 'panel'
              return (
                <Grid2 container spacing={2}>
                  <Grid2 container size={{ xs: 12, md: !isPanel ? 6 : 12 }}>
                    <Grid2
                      size={{ xs: 12 }}
                      sx={{
                        bgcolor: 'background.paper',
                        border: `1px solid ${Mx8Theme.palette.divider}`,
                      }}
                    >
                      <Labeled
                        label="Audience"
                        fullWidth
                        sx={{
                          padding: '15px 10px 15px 0',
                          '& > p.RaLabeled-label': {
                            padding: '0 30px',
                            color: 'inherit',
                            fontSize: '20px',
                            fontWeight: 600,
                          },
                        }}
                      >
                        <CodeField
                          source={`${sourcePrefix}json_audience`}
                          language="json"
                          height={isBasic ? '70vh' : '40vh'}
                          /* c8 ignore next 4*/
                          defaultValue={
                            template?.json_audience ||
                            templateData[0].json_audience
                          }
                          sx={{
                            border: 0,
                            padding: 0,
                            ...(isEditDisable && {
                              pointerEvents: 'none',
                              opacity: 0.7,
                            }),
                          }}
                        />
                      </Labeled>
                    </Grid2>
                  </Grid2>
                  {isBasic && (
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <Box paddingBottom="5px">
                        <Typography
                          display="inline-block"
                          fontSize="20px"
                          fontWeight="600"
                          variant="h4"
                          paddingRight="15px"
                        >
                          Sample Integration
                        </Typography>
                        <Typography
                          display="inline-block"
                          fontSize="15px"
                          fontWeight={500}
                          color={Mx8Theme.palette.primary.main}
                        >
                          How does this work? Click{' '}
                          <Link
                            color="inherit"
                            href="https://help.mx8.io/en/articles/9759966-set-up-an-external-sample-provider"
                            rel="noopener noreferrer"
                            title="Set up an external sample provider"
                            target="_blank"
                          >
                            here
                          </Link>
                        </Typography>
                      </Box>
                      <Box>
                        {data?.survey_url && (
                          <Grid2
                            container
                            paddingBottom="5px"
                            color={Mx8Theme.palette.divider}
                          >
                            <Grid2
                              size={{ xs: 11 }}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                fontWeight={600}
                                fontSize="15px"
                                whiteSpace="nowrap"
                                paddingRight="5px"
                              >
                                Survey URL:
                              </Typography>
                              <TextField
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                fontSize="13px"
                                source="survey_url"
                              />
                            </Grid2>
                            <Grid2 size={{ xs: 1 }}>
                              <Tooltip title="Copy to clipboard">
                                <IconButton
                                  data-testid="copy-url-btn"
                                  onClick={handleCopy}
                                  disabled={isLoading}
                                >
                                  <CopyAll />
                                </IconButton>
                              </Tooltip>
                            </Grid2>
                          </Grid2>
                        )}
                        {sampleFields.map((ele, idx) => (
                          <TextInput
                            key={`url-${ele.name}-${idx}`}
                            source={`${sourcePrefix}urls.${ele.name}`}
                            label={`URL for ${ele.name.replace('_', ' ')}`}
                            defaultValue={ele.value}
                            className="white-bg"
                            required
                            fullWidth
                          />
                        ))}
                        <TextInput
                          source={`${sourcePrefix}respondent_id_field`}
                          label="Respondent identification field"
                          defaultValue="respondent_id"
                          className="white-bg"
                          required
                          fullWidth
                        />
                      </Box>
                    </Grid2>
                  )}
                  {audienceType === 'first_party' && (
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <FileInput
                        source={`${sourcePrefix}excel_sheet`}
                        label="Contacts"
                        type="file"
                        accept={{
                          'application/vnd.ms-excel': ['.xls'],
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                            ['.xlsx'],
                        }}
                        placeholder={
                          <p>
                            Drop an Excel file to upload it, or click to select
                            it.
                          </p>
                        }
                        isRequired
                        sx={{ paddingTop: '20px' }}
                      >
                        <FileField
                          source={`${sourcePrefix}src`}
                          title="title"
                          download={formData.excel_sheet?.title}
                        />
                      </FileInput>
                    </Grid2>
                  )}
                </Grid2>
              )
            }}
          </FormDataConsumer>
          {/* end added panel audience */}
        </Grid2>
      </Grid2>
    </>
  )
}

export default AudienceCommonInput
