import { Box, Dialog, Grid2, MenuItem, Select } from '@mui/material'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import {
  Button,
  DeleteButton,
  Identifier,
  Labeled,
  RaRecord,
  useNotify,
  useRefresh,
  useStore,
  WithRecord,
} from 'react-admin'
import { FC, useEffect, useState } from 'react'
import { Analytics, Create, Settings, ViewList } from '@mui/icons-material'
import ReportEditDialog from '../pages/Reports/components/ReportEditDialog'
import { Mx8Theme } from '../layout'
import ReportConfigure from '../pages/Reports/components/ReportConfigure'
import { CrossTabPager, RecordType } from '../utils/types'
import FileDownload from './FileDownload/FileDownload'
import { ReportingDatasetSelect, CrossTabPageSelect } from '.'
import { NavigationProps } from './types'

const Navigator: FC<NavigationProps> = (props) => {
  const { ReportElement } = props
  const refresh = useRefresh()
  const value = `No ${props.reportType}s available`
  const reports_list = props.reportList
  const [editInDialogOpen, setEditInDialogOpen] = useState(false)
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false)
  const [openProjectConfigure, setOpenProjectConfigure] = useState(false)
  const notify = useNotify()
  // Setup paging
  const [currentPage, setCurrentPage] = useStore(
    `crossTabPage-${props.reportType}-${props.uniqueId}`,
  )

  const [pageData, setPageData] = useState<CrossTabPager>()

  // Get the current reports
  const [currentReportId, setCurrentReportId] = useStore<Identifier>(
    `current-${props.reportType}-${props.uniqueId}`,
  )

  useEffect(() => {
    if (reports_list?.length && reports_list?.length > 0 && !currentReportId) {
      setCurrentReportId(reports_list[0].id)
    }
  }, [reports_list, currentReportId, setCurrentReportId])

  const getDatasets = () => {
    return (
      reports_list?.find((report: RaRecord) => report.id == currentReportId)
        ?.datasets || []
    )
  }
  /* c8 ignore next 3 */
  const getProjectId = (record: RecordType): string => {
    return record.project_id ?? record.id ?? ''
  }

  /* c8 ignore next 10 */
  const handleMutations = {
    onSuccess: (record: RaRecord) => {
      setCurrentReportId(record.id as string)
      notify(`${props?.reportType} created successfully`, {
        type: 'success',
        undoable: false,
      })
      refresh()
      props?.reportType == 'report' && setEditInDialogOpen(true)
    },
  }

  const isFromProject = props.uniqueId.includes('project')

  const hasReport =
    reports_list?.length && reports_list?.length > 0 && !props.disabled
  // Return a drop down list of the reports to choose from followed by the currently rendered report

  const EditElement = props.EditElement
  const AnalysisElement = props.AnalysisElement

  return (
    <Grid2 width="100%" container spacing={2}>
      <Grid2 size={{ md: 5, xs: 12 }}>
        <Labeled fullWidth>
          {hasReport ? (
            <Select
              data-testid={`${props.reportType}-selector`}
              size="small"
              fullWidth
              label={props.reportLabel}
              value={currentReportId}
              /* c8 ignore next 3 */
              onChange={(event) => {
                setCurrentReportId(`${event.target.value}`)
              }}
            >
              {reports_list.map((report: RaRecord) => {
                return (
                  <MenuItem
                    data-testid={`${props.reportType}-selector-${report.id}`}
                    key={report.id}
                    value={report.id}
                  >
                    {report[props.reportNameField]}
                  </MenuItem>
                )
              })}
            </Select>
          ) : (
            <Select
              data-testid={`${props.reportType}-selector`}
              label={props.reportLabel}
              size="small"
              value={value}
              fullWidth
              disabled
            >
              <MenuItem
                key={value}
                data-testid={`${props.reportType}-selector-${value}`}
                value={value}
              >
                {value}
              </MenuItem>
            </Select>
          )}
        </Labeled>
      </Grid2>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{ display: 'flex', justifyContent: 'start' }}
      >
        <Box
          display="flex"
          justifyContent="start"
          flexDirection="row"
          flexWrap={`wrap`}
          gap="5px"
          sx={{ marginTop: { sm: '20px', xs: null } }}
        >
          {hasReport ? (
            <>
              {props.reportType === 'insight' ? (
                <Button
                  aria-label={`Edit ${props.reportType}`}
                  /* c8 ignore next 3 */
                  onClick={() => {
                    setEditInDialogOpen(true)
                  }}
                  label="Edit"
                  variant="text"
                >
                  <Create />
                </Button>
              ) : (
                <Button
                  aria-label={`Questions ${props.reportType}`}
                  /* c8 ignore next 3 */
                  onClick={() => {
                    setEditInDialogOpen(true)
                  }}
                  label="Questions"
                  variant="text"
                >
                  <ViewList />
                </Button>
              )}

              {AnalysisElement && (
                <Button
                  aria-label={`Analysis ${props.reportType}`}
                  label="Analysis"
                  variant="text"
                  onClick={() => setOpenAnalysisModal(true)}
                  data-testid="analysis-open-btn"
                >
                  <Analytics />
                </Button>
              )}
              {props?.reportType !== 'insight' && (
                <FileDownload
                  source=""
                  type="report"
                  dataset={props.currentDataset}
                  reportId={currentReportId as number}
                  variant="text"
                  sx={{
                    margin: 0,
                    fontSize: '13px',
                    color: Mx8Theme.palette.primary.main,
                    fontWeight: 600,
                  }}
                />
              )}
              <ReportEditDialog
                currentReportId={currentReportId}
                isOpen={editInDialogOpen}
                reportList={props.reportList}
                reportResource={props.reportResource}
                reportType={props.reportType}
                setCurrentReportId={setCurrentReportId}
                setIsOpen={setEditInDialogOpen}
                title={
                  props.reportType === 'insight'
                    ? 'Edit Insights'
                    : 'Report Questions'
                }
                setAnalysisOpen={setOpenAnalysisModal}
              >
                <EditElement hideSurvey={props?.hideSurvey || false} />
              </ReportEditDialog>
              {AnalysisElement && (
                <ReportEditDialog
                  currentReportId={currentReportId}
                  isOpen={openAnalysisModal}
                  reportList={props.reportList}
                  reportResource={props.reportResource}
                  reportType={props.reportType}
                  setCurrentReportId={setCurrentReportId}
                  setIsOpen={setOpenAnalysisModal}
                  title="Configure Analysis"
                >
                  <AnalysisElement />
                </ReportEditDialog>
              )}
              {props?.reportType !== 'insight' && (
                <DeleteButton
                  resource="reports"
                  record={{ id: currentReportId }}
                />
              )}
            </>
          ) : null}
          {props?.CreateElement ? (
            <WithRecord
              render={(record) => (
                <CreateInDialogButton
                  ButtonProps={{
                    'aria-label': `Create ${props.reportLabel}`,
                  }}
                  record={{
                    project_id: getProjectId(record),
                    survey_id: record.id,
                  }}
                  fullWidth
                  sx={{
                    '& .MuiDialog-paper': {
                      maxHeight: 'calc(100vh - 165px)',
                    },
                  }}
                  resource={props.reportResource}
                  /* c8 ignore next 9 */
                  label={props.customLabel || `Create ${props.reportLabel}`}
                  mutationOptions={handleMutations}
                >
                  {props.CreateElement && <props.CreateElement />}
                </CreateInDialogButton>
              )}
            />
          ) : null}
          {isFromProject && hasReport ? (
            <Button
              aria-label="Project Report Configure"
              label="Configure"
              variant="text"
              onClick={() => setOpenProjectConfigure(true)}
              data-testid="configure-open-btn"
            >
              <Settings />
            </Button>
          ) : null}
        </Box>
      </Grid2>
      <Grid2
        size={{ xs: 12 }}
        flex={1}
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: { xs: 'start', sm: 'end' },
        }}
      >
        <ReportingDatasetSelect
          datasets={getDatasets()}
          currentDataset={props.currentDataset}
          setCurrentDataset={props.setCurrentDataset}
        />
        <CrossTabPageSelect
          pageData={pageData}
          crossTabPage={currentPage}
          setCrossTabPage={setCurrentPage}
        />
      </Grid2>

      <Grid2 size={{ xs: 12 }} paddingTop={2}>
        {hasReport ? (
          <ReportElement
            isInsightSurvey
            source="cross_tab"
            crossTabPage={currentPage}
            reportId={currentReportId}
            dataset={props.currentDataset}
            setPageData={setPageData}
            setCrossTabPage={setCurrentPage}
          />
        ) : null}
      </Grid2>
      {openProjectConfigure ? (
        <Dialog
          open={openProjectConfigure}
          /* c8 ignore next 4 */
          onClose={() => {
            setOpenProjectConfigure(false)
            refresh()
          }}
          sx={{
            '.MuiPaper-root': { width: '90%', margin: 0 },
          }}
        >
          <ReportConfigure />
        </Dialog>
      ) : null}
    </Grid2>
  )
}

export default Navigator
