import { SxProps } from '@mui/system'
import { Mx8Theme } from '../../../layout'

export const markdownContainer: SxProps = {
  '& .toastui-editor-ww-container>.toastui-editor': {
    backgroundColor: 'background.paper',
  },
  '& .toastui-editor-defaultUI-toolbar': {
    backgroundColor: Mx8Theme.palette.divider,
  },
  '& .toastui-editor-defaultUI-toolbar button': {
    borderColor: Mx8Theme.palette.divider,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  '& .toastui-editor-defaultUI-toolbar button:not(:disabled):hover': {
    backgroundColor: 'background.paper',
    borderColor: 'background.paper',
  },
}
