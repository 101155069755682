import { SxProps } from '@mui/system'
import { Mx8Theme } from '../../../../layout'

export const loaderContainer: SxProps = {
  minHeight: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const parentList = (
  itemWidth: string | null,
  isFirstColumn: boolean,
) => ({
  width: '100%',
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& > li': {
    minWidth: '100px',
    width: itemWidth,
    '&:first-of-type': {
      width: isFirstColumn ? itemWidth : `calc(${itemWidth} - 1px)`,
    },
  },
})

export const reportNameContainer = (
  isFirstColumn: boolean,
  isLastChild: boolean,
) => ({
  /* c8 ignore next 3 */
  backgroundColor: isFirstColumn ? 'background.default' : null,
  padding: 0,
  border: `1px solid ${Mx8Theme.palette.divider}`,
  borderBottom: isLastChild ? null : 0,
  borderRight: 0,
})

export const checkBoxContainer = (isLastChild: boolean) => ({
  display: 'flex',
  justifyContent: 'center',
  border: `1px solid ${Mx8Theme.palette.divider}`,
  borderBottom: isLastChild ? null : 0,
  '&:not(:last-child)': {
    borderRight: 0,
  },
})

export const surveyNameContainer = {
  backgroundColor: 'background.default',
  padding: 0,
  border: `1px solid ${Mx8Theme.palette.divider}`,
  '&:not(:last-child)': { borderRight: 0 },
  borderBottom: 0,
}
