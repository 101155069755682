import { Mx8Theme } from '../../../../layout'

export const drawerContainer = {
  paddingLeft: '2%',
  position: 'absolute',
  top: '13px',
  right: '2%',
  zIndex: 10,
}

export const badgeStyle = (isIncomplete: boolean) => ({
  '& .MuiBadge-badge': {
    backgroundColor: isIncomplete
      ? Mx8Theme.palette.error.main
      : Mx8Theme.palette.secondary.main,
    color: 'background.paper',
  },
})

export const iconBtnStyle = {
  padding: 0,
  backgroundColor: 'background.paper',
}

export const checkBoxStyle = {
  padding: 0,
  color: Mx8Theme.palette.secondary.main,
}

export const drawerStyle = {
  paddingTop: '20px',
  [`& .MuiDrawer-paper`]: {
    boxSizing: 'border-box',
  },
}

export const checkListContainer = {
  boxSizing: 'border-box',
  height: '87vh',
  width: { xs: '280px', sm: '400px' },
  padding: '25px 10px 65px',
  transform: 'translateY(48px)',
}

export const loaderContainer = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const checkListChildStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 1,
  position: 'absolute',
  bottom: 0,
  right: 0,
}
