import { SurveyData, SurveyProps } from './types'

export const filterSelectedSurveys = (
  id: number,
  surveys: SurveyProps,
  data: SurveyData,
) => {
  const selectedSurveys = surveys?.map((survey) => survey.survey_id)
  /* c8 ignore next */
  const filter = data?.filter(
    (item) => !selectedSurveys?.includes(item.id) || id === item.id,
  )
  return filter
}

export const getSurveyId = (urlString: string) => {
  const regex: RegExp = /\d+/
  const match: RegExpExecArray | null = regex.exec(urlString)

  const surveyIdFromSurvey: number = match ? parseInt(match[0]) : NaN
  if (urlString.includes('surveys')) {
    return surveyIdFromSurvey
  } else {
    return
  }
}
