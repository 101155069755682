import { InputProps, useInput } from 'react-admin'
import { FC, useEffect, useState } from 'react'
import BillingDetailsForm from '../BillingDetailsForm'
import { TypeOfAllInput } from '../../types'

export const BillingDetailsCreate: FC<InputProps> = (props) => {
  const [allInput, setAllInput] = useState<TypeOfAllInput>({
    name: '',
    email: '',
    address_line_1: '',
    address_line_2: '',
    locality: '',
    administrative_district_level_1: '',
    postal_code: '',
    country: 'US',
  })
  const {
    field: { onChange },
  } = useInput(props)
  useEffect(() => {
    onChange({ ...allInput })
  }, [allInput])

  return (
    <BillingDetailsForm
      allInput={allInput}
      setAllInput={setAllInput}
      isCreate
    />
  )
}

export const BillingDetailsEdit: FC<InputProps> = (props: InputProps) => {
  const {
    field: { onChange, value },
  } = useInput(props)
  const [allInput, setAllInput] = useState<TypeOfAllInput>({
    name: value.name,
    email: value.email,
    address_line_1: value.address_line_1,
    address_line_2: value.address_line_2,
    locality: value.locality,
    administrative_district_level_1: value.administrative_district_level_1,
    postal_code: value.postal_code,
    country: value.country,
  })

  useEffect(() => {
    onChange(allInput)
  }, [allInput])

  return <BillingDetailsForm allInput={allInput} setAllInput={setAllInput} />
}
