import { FC } from 'react'
import { required, SelectInput } from 'react-admin'
import { SIGNIFICANCE_DATA } from '../../../utils/constants'

const SignificanceInput: FC<{ dataTestId?: string }> = ({ dataTestId }) => {
  return (
    <SelectInput
      id="stat_test"
      source="stat_test"
      data-testid={`stat_test-${dataTestId ?? 'edit'}`}
      choices={Array.from(SIGNIFICANCE_DATA).map(([name, id]) => ({
        name,
        id,
      }))}
      label="Significance Testing"
      fullWidth
      defaultValue="residual_t"
      validate={required()}
    />
  )
}

export default SignificanceInput
