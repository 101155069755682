import { Avatar, Box, Button, IconButton, Typography } from '@mui/material'
import { FC } from 'react'
import {
  chatResponseContainer,
  commonContainer,
  headerContainer,
  headerInfo,
  headerName,
  inputContainer,
  placeholderContainer,
  textContent,
  wrapperStyle,
} from './style'
import Markdown from 'markdown-to-jsx'
import { Send } from '@mui/icons-material'
import { ThemeType } from '../../../../../../types'
import { DUMMY_QUESTION } from '../../../../../../constants'
import { actionBtn, chipWrapper, optionContainer } from '../../style'

const Portrait: FC<{ customTheme: ThemeType }> = ({ customTheme }) => {
  return (
    <>
      <Box
        data-testid="chat-header"
        sx={headerContainer(customTheme?.main_color)}
      >
        <Avatar
          data-testid="avatar"
          alt={'Shanna'}
          src={customTheme?.icon_image}
          sx={{ width: 35, height: 35 }}
        />
        <Box sx={{ marginLeft: '15px' }}>
          <Box sx={headerInfo(customTheme?.secondary_color)}>
            {customTheme?.line_1}
          </Box>
          <Box sx={headerName(customTheme?.secondary_color)}>
            {customTheme?.line_2}
          </Box>
        </Box>
      </Box>
      <Box sx={chatResponseContainer}>
        <Box display="flex" flexDirection="column">
          <Box sx={commonContainer}>
            <Box
              sx={wrapperStyle(customTheme?.secondary_color)}
              data-testid="others-rank-sorter-wrapper"
            >
              {/* istanbul ignore next */}
              <Typography
                variant="body1"
                sx={textContent}
                data-testid="others-rank-sorter-content"
              >
                <Markdown
                  options={{
                    overrides: {
                      img: {
                        props: {
                          className: 'custom-image', // Apply custom Material-UI styles
                        },
                      },
                    },
                    disableParsingRawHTML: true,
                  }}
                >
                  {DUMMY_QUESTION}
                </Markdown>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box data-testid="select-input" width="100%">
        <Box sx={optionContainer(false)}>
          <Box sx={chipWrapper(false)}>
            <Button
              sx={actionBtn(
                customTheme?.main_color,
                customTheme?.secondary_color,
              )}
            >
              Yes
            </Button>
            <Button
              sx={actionBtn(
                customTheme?.main_color,
                customTheme?.secondary_color,
              )}
            >
              No
            </Button>
          </Box>
        </Box>
        <Box
          data-testid="default-input"
          sx={inputContainer(customTheme?.main_color)}
        >
          <Box
            data-testid="default-input-placeholder"
            sx={placeholderContainer(customTheme?.secondary_color)}
          >
            {customTheme?.hints?.multichoice}
          </Box>
          <IconButton sx={{ color: customTheme?.secondary_color }}>
            <Send />
          </IconButton>
        </Box>
      </Box>
    </>
  )
}

export default Portrait
