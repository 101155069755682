import '@fontsource/roboto/400.css'
import '@fontsource/roboto/700.css'
import { defaultTheme } from 'react-admin'

interface ThemeColors {
  primaryMain: string
  primaryLight: string
  secondaryMain: string
  secondaryLight: string
  errorMain: string
  errorLight: string
  successMain: string
  successLight: string
  infoMain: string
  infoLight: string
  divider: string
  backgroundDefault: string
  backgroundPaper: string
  textPrimary: string
  textSecondary: string
  textEditor: string
  labelPrimary: string
  tagsPrimary: string
}

/**
 * Base theme function with dynamic colors.
 */
const createMx8Theme = (mode: 'light' | 'dark', colors: ThemeColors) => ({
  palette: {
    mode,
    primary: { main: colors.primaryMain, light: colors.primaryLight },
    secondary: { main: colors.secondaryMain, light: colors.secondaryLight },
    error: { main: colors.errorMain, light: colors.errorLight },
    success: { main: colors.successMain, light: colors.successLight },
    info: { main: colors.infoMain, light: colors.infoLight },
    divider: colors.divider,
    background: {
      default: colors.backgroundDefault,
      paper: colors.backgroundPaper,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      editor: colors.textEditor,
      tags: colors.tagsPrimary,
    },
    label: { primary: colors.labelPrimary },
  },
  spacing: 8,
  shape: { borderRadius: 0 },
  typography: {
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 700,
  },
  components: {
    ...defaultTheme.components,
    MuiButtonBase: {
      defaultProps: { disableRipple: true },
    },
    MuiCard: {
      styleOverrides: {
        root: { backgroundColor: colors.backgroundPaper, boxShadow: 'none' },
      },
    },
    MuiSelect: {
      defaultProps: {
        ...defaultTheme.components?.MuiSelect?.defaultProps,
        fullWidth: undefined,
        variant: 'outlined' as const,
      },
      styleOverrides: { root: { backgroundColor: colors.backgroundPaper } },
    },
    MuiTextField: {
      defaultProps: {
        ...defaultTheme.components?.MuiTextField?.defaultProps,
        fullWidth: undefined,
        variant: 'outlined' as const,
      },
      styleOverrides: { root: { margin: 0 } },
    },
    MuiInputBase: {
      defaultProps: {
        ...defaultTheme.components?.MuiInputBase?.defaultProps,
        fullWidth: undefined,
      },
      styleOverrides: {
        root: { backgroundColor: colors.backgroundPaper },
        input: { backgroundColor: colors.backgroundPaper },
      },
    },
    MuiFormControl: {
      defaultProps: {
        ...defaultTheme.components?.MuiFormControl?.defaultProps,
        variant: 'outlined' as const,
      },
    },
    MuiTableCell: {
      defaultProps: {
        ...defaultTheme.components?.MuiTableCell?.defaultProps,
        variant: 'outlined' as const,
      },
      styleOverrides: { root: { padding: '5px 10px' } },
    },
    RaEdit: {
      defaultProps: {
        ...defaultTheme.components?.RaEdit?.defaultProps,
        variant: 'outlined' as const,
      },
      styleOverrides: {
        root: {
          paddingBottom: '20px',
          height: '100%',
          '& .RaEdit-noActions': { marginTop: 0, height: '100%' },
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          padding: '8px 10px 0',
          backgroundColor: colors.backgroundPaper,
          border: `1px solid ${colors.divider}`,
          boxShadow: 'none',
          '& .MuiPaper-elevation': {
            boxShadow: 'none',
          },
        },
        content: {},
      },
    },
    MuiAutocomplete: { defaultProps: { fullWidth: undefined } },
    RaSimpleFormIterator: { defaultProps: { fullWidth: undefined } },
    RaTranslatableInputs: { defaultProps: { fullWidth: undefined } },
  },
})

/**
 * Define light theme with specific colors.
 */
export const Mx8Theme = createMx8Theme('light', {
  primaryMain: '#317CFE',
  primaryLight: '#798499',
  secondaryMain: '#A3BAE6',
  secondaryLight: '#B5C7EB',
  errorMain: '#FF6F61',
  errorLight: '#FF8B80',
  successMain: '#3FD29C',
  successLight: '#65DBAF',
  infoMain: '#A3BAE6',
  infoLight: '#5A96FE',
  divider: '#DBD2C8',
  backgroundDefault: '#FFF7ed',
  backgroundPaper: '#fffefa',
  textPrimary: '#212324',
  textSecondary: '#586680',
  textEditor: '#000',
  labelPrimary: 'white',
  tagsPrimary: '#317CFE',
})

/**
 * Define dark theme with specific colors.
 */
export const Mx8DarkTheme = createMx8Theme('dark', {
  primaryMain: '#317CFE',
  primaryLight: '#5A96FE',
  secondaryMain: '#A3BAE6',
  secondaryLight: '#B5C7EB',
  errorMain: '#FF6F61',
  errorLight: '#FF8B80',
  successMain: '#3FD29C',
  successLight: '#65DBAF',
  infoMain: '#A3BAE6',
  infoLight: '#5A96FE',
  divider: '#333333',
  backgroundDefault: '#121212',
  backgroundPaper: '#1E1E1E',
  textPrimary: '#ffffff',
  textSecondary: '#b0bec5',
  textEditor: '#fff',
  labelPrimary: '#434343',
  tagsPrimary: '#a3bae6',
})

export const mx8DarkThemeMonaco = {
  background: '#1e1e1e',
  comment: '#6a9955',
  string: '#ce9178',
  constantLanguage: '#569cd6',
  keyword: '#569cd6',
  storage: '#569cd6',
  entityNameType: '#4ec9b0',
  entityNameFunction: '#dcdcaa',
  supportFunction: '#4ec9b0',
  supportConstant: '#4ec9b0',
  supportType: '#4ec9b0',
  supportClass: '#4ec9b0',
  supportVariable: '#4ec9b0',
  invalidForeground: '#ffffff',
  invalidBackground: '#f44747',
  placeholder: '#569cd6',
  editorForeground: '#d4d4d4',
  editorBackground: '#1e1e1e',
  selectionBackground: '#264f78',
  lineHighlightBackground: '#333333',
  cursorForeground: '#d4d4d4',
  whitespaceForeground: '#3b3a32',
  lineNumberForeground: '#858585',
  activeLineNumberForeground: '#c6c6c6',
}
