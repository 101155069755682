import { SxProps } from '@mui/material'
import { Mx8Theme } from '../../../../layout'

export const container: SxProps = {
  display: 'flex',
  marginBottom: '20px',
  alignItems: 'center',
}
export const updateBtnStyle: SxProps = {
  color: {
    sm: 'background.default',
    xs: Mx8Theme.palette.primary.main,
  },
  padding: '6px 14px',
  '&:hover': { backgroundColor: Mx8Theme.palette.primary.main },
}

export const updateBtnContainer: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: { xs: null, sm: 2 },
  flex: '1 1 auto',
}

export const iconContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  flex: '1 1 auto',
}
