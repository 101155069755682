import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid2,
} from '@mui/material'
import React, { useState } from 'react'
import { InputProps, useNotify, useRecordContext } from 'react-admin'
import MediaEditorCell from '../EditorCell'
import MediaModal from '../Modal'
import { Column, OpenModelUrlType, Row } from '../../../types'

const MediaShow: React.FC<InputProps> = (props: InputProps) => {
  const record = useRecordContext(props)

  const media = record?.media
  const [openModelUrl, setOpenModelUrl] = useState<OpenModelUrlType>({
    playBackUrl: '',
    modelOpen: false,
    conditionCheck: '',
  })
  const notify = useNotify()

  return (
    <>
      <Grid2 size={{ xs: 12 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow key="header">
                {media?.columns?.length > 0 &&
                  media?.columns?.map((column: Column) => (
                    <TableCell key={column?.name}>
                      <Box display="flex" alignItems="left">
                        {column?.name}
                      </Box>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {media?.rows?.length > 0 ? (
                media?.rows?.map((row: Row, rowIndex: number) => (
                  <TableRow key={row['File Name'] as string}>
                    {media?.columns?.map((column: Column) => (
                      <TableCell key={column?.name}>
                        <MediaEditorCell
                          cell={row[column?.name]}
                          rowIndex={rowIndex}
                          type={column?.type}
                          setOpenModelUrl={setOpenModelUrl}
                          record_Survey_key={record?.survey_key}
                          row={row}
                          notify={notify}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow key="empty">
                  <TableCell>No media</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>

      <MediaModal
        setOpenModelUrl={setOpenModelUrl}
        openModelUrl={openModelUrl}
      />
    </>
  )
}

export default MediaShow
