import { FC } from 'react'
import { Box, Grid2, TextField } from '@mui/material'
import { SelectInput } from 'react-admin'
import { switchCountryData } from '../../constants'
import {
  BillingDetailsFormType,
  ParentProps,
  SetAllInputProps,
  TypeOfAllInput,
} from '../../types'
import { CountryMap } from '../../../Audiences/constants'

export const handleAddFunc = (
  fieldName: string,
  fieldValue: string,
  setAllInput: SetAllInputProps,
) => {
  setAllInput((prevInput: TypeOfAllInput) => ({
    ...prevInput,
    [fieldName]: fieldValue,
  }))
}

export const countrySwitchFun = (countryName: string) => {
  for (const item of switchCountryData) {
    if (countryName === item.country_N) {
      return {
        postal_code: `${item.postal_code}`,
        locality: `${item.locality}`,
        administrative_district_level_1: `${item.administrative_district_level_1}`,
      }
    }
  }
  return {
    country_N: 'US',
    postal_code: 'ZIP code',
    locality: 'City',
    administrative_district_level_1: 'State',
  }
}

const CountryInputForAccount: React.FC<ParentProps> = ({
  handleAddFunc,
  countryName,
  setAllInput,
}) => {
  return (
    <SelectInput
      source="country"
      data-testid={'country-select-billing'}
      /* c8 ignore next 1 */
      onChange={(e) => handleAddFunc('country', e.target.value, setAllInput)}
      fullWidth
      defaultValue={countryName}
      choices={Array.from(CountryMap).map(([name, id]) => ({ name, id }))}
    />
  )
}
const BillingDetailsForm: FC<BillingDetailsFormType> = (props) => {
  const { allInput, setAllInput, isCreate } = props
  const hideCondition =
    countrySwitchFun(allInput.country)?.administrative_district_level_1 ===
    'N/A'
  return (
    <Box width="100%">
      <CountryInputForAccount
        countryName={allInput?.country}
        setAllInput={setAllInput}
        handleAddFunc={handleAddFunc}
      />
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            data-testid={`billing-${isCreate ? 'create' : 'edit'}-name`}
            value={allInput?.name}
            label="Name"
            onChange={(e) => handleAddFunc('name', e.target.value, setAllInput)}
            variant="outlined"
            size="small"
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            data-testid={`billing-${isCreate ? 'create' : 'edit'}-email`}
            value={allInput?.email}
            label="E-mail"
            onChange={(e) =>
              handleAddFunc('email', e.target.value, setAllInput)
            }
            variant="outlined"
            size="small"
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            value={allInput?.address_line_1}
            data-testid={`Address-line-1-${isCreate ? 'create' : 'edit'}`}
            label="Address line 1"
            onChange={(e) =>
              handleAddFunc('address_line_1', e.target.value, setAllInput)
            }
            variant="outlined"
            size="small"
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            value={allInput?.address_line_2}
            data-testid={`Address-line-2-${isCreate ? 'create' : 'edit'}`}
            label="Address line 2"
            onChange={(e) =>
              handleAddFunc('address_line_2', e.target.value, setAllInput)
            }
            variant="outlined"
            size="small"
          />
        </Grid2>
        {!hideCondition && (
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <TextField
              fullWidth
              data-testid={`administrative_district_level_1-${isCreate ? 'create' : 'edit'}`}
              value={allInput?.administrative_district_level_1}
              label={
                countrySwitchFun(allInput?.country)
                  ?.administrative_district_level_1
              }
              onChange={(e) =>
                handleAddFunc(
                  'administrative_district_level_1',
                  e.target.value,
                  setAllInput,
                )
              }
              variant="outlined"
              size="small"
            />
          </Grid2>
        )}

        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            data-testid={`locality-${isCreate ? 'create' : 'edit'}`}
            value={allInput?.locality}
            label={countrySwitchFun(allInput?.country)?.locality}
            onChange={(e) =>
              handleAddFunc('locality', e.target.value, setAllInput)
            }
            variant="outlined"
            size="small"
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            value={allInput?.postal_code}
            data-testid={`postal_code-${isCreate ? 'create' : 'edit'}`}
            label={countrySwitchFun(allInput?.country)?.postal_code}
            onChange={(e) =>
              handleAddFunc('postal_code', e.target.value, setAllInput)
            }
            variant="outlined"
            size="small"
          />
        </Grid2>
      </Grid2>
    </Box>
  )
}
export default BillingDetailsForm
