import { ChangeEvent, FC } from 'react'
import { CardMedia, Checkbox, TextField, Typography, Box } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { VideoViewFunc } from '../utils'
import { MediaEditorCellProps } from '../../../types'
import {
  fileNameStyle,
  textFieldStyle,
  thumbnailStyle,
  visibilityIconContainer,
  visibilityIconStyle,
} from './style'

const MediaEditorCell: FC<MediaEditorCellProps> = (props) => {
  const {
    cell,
    type,
    rowIndex,
    column,
    handleOnchage,
    columnIndex,
    wrongColumnNameError,
    invalid,
    row,
    record_Survey_key,
    setOpenModelUrl,
    notify,
  } = props
  const ErrorFunc = (column: string) => {
    if (columnIndex) {
      if (
        wrongColumnNameError?.columns[columnIndex]?.name?.message?.includes(
          column,
        )
      ) {
        return {
          columnTrue: true,
          message: wrongColumnNameError?.columns[columnIndex]?.name.message,
        }
      }
    }
  }

  const previewFun = () => {
    if ((row?.file_name as string)?.includes('.mp4')) {
      VideoViewFunc(row.file_name, record_Survey_key, setOpenModelUrl, notify)
    } else {
      setOpenModelUrl({
        playBackUrl: cell,
        modelOpen: true,
        conditionCheck: row?.file_name as string,
      })
    }
  }

  const handleTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { type, value, checked } = event.target as HTMLInputElement
    /* c8 ignore next 1 */
    const inputValue = type === 'checkbox' ? checked : value
    if (handleOnchage) handleOnchage(rowIndex, column as string, inputValue)
  }

  const getHelperText =
    columnIndex && invalid
      ? wrongColumnNameError?.columns[columnIndex]?.name.message
      : ''

  const renderMediaEditorComponent = () => {
    switch (type) {
      case 'thumbnail':
        return (
          <Box sx={{ position: 'relative' }}>
            <CardMedia
              data-testid="thumbnail-image"
              component="img"
              image={cell as string}
              alt="Thumbnail of the media"
              height="70"
              sx={thumbnailStyle}
            />
            <Box sx={visibilityIconContainer}>
              <VisibilityIcon
                data-testid={`view-icone-${rowIndex}`}
                onClick={previewFun}
                style={visibilityIconStyle}
              />
            </Box>
          </Box>
        )
      case 'file_name':
        return (
          <Typography variant="caption" sx={fileNameStyle}>
            {cell}
          </Typography>
        )
      case 'number':
        return (
          <TextField
            data-testid={`number-input-${columnIndex?.toString() + rowIndex?.toString()}`}
            /* c8 ignore next */
            error={invalid && ErrorFunc(column as string)?.columnTrue}
            helperText={getHelperText}
            type="number"
            sx={textFieldStyle}
            value={cell}
            size="small"
            onChange={handleTextChange}
          />
        )
      case 'text':
        return (
          <TextField
            data-testid={`text-input-${columnIndex?.toString() + rowIndex?.toString()}`}
            onChange={handleTextChange}
            error={invalid && ErrorFunc(column as string)?.columnTrue}
            helperText={getHelperText}
            value={cell}
            type="text"
            size="small"
            sx={textFieldStyle}
          />
        )
      default:
        return (
          <Box>
            <Checkbox
              checked={cell as boolean}
              data-testid={`text-input-${columnIndex?.toString() + rowIndex?.toString()}`}
              size="small"
              onChange={handleTextChange}
            />
            <Typography color={'red'} fontSize={12}>
              {/* c8 ignore next */}
              {invalid ? ErrorFunc(column as string)?.message : ''}
            </Typography>
          </Box>
        )
    }
  }

  return renderMediaEditorComponent()
}

export default MediaEditorCell
