import { FC } from 'react'
import { required, SelectInput } from 'react-admin'
import { CONFIDENCE_DATA } from '../../../utils/constants'

const ConfidenceInput: FC<{ dataTestId?: string }> = ({ dataTestId }) => {
  return (
    <SelectInput
      id="confidence_level"
      source="confidence_level"
      data-testid={`confidence_level-${dataTestId ?? 'edit'}`}
      choices={Array.from(CONFIDENCE_DATA).map(([name, id]) => ({ name, id }))}
      label="Confidence Level"
      fullWidth
      defaultValue="0.95"
      validate={required()}
    />
  )
}

export default ConfidenceInput
