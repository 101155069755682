import { Box } from '@mui/material'
import { CreditLimitList } from '../../CreditLimit/List'
import { TransactionList } from '../../Transactions/List'
import { SubscriptionList } from '../List'
import { SuperUserAccountList } from '../AccountsList'

export const SuperUserLayout = ({ ...props }) => {
  const iSuper = sessionStorage.getItem('super-user')
  if (iSuper !== 'true') return null

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap' },
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <Box>
          <TransactionList {...props} />
        </Box>
        <Box>
          <CreditLimitList {...props} />
        </Box>
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <Box>
          <SuperUserAccountList {...props} />
        </Box>
        <Box>
          <SubscriptionList {...props} />
        </Box>
      </Box>
    </Box>
  )
}
