import { FC } from 'react'
import { Labeled } from 'react-admin'
import { MenuItem, Select } from '@mui/material'
import { CrossTabPageProps } from '../pages/Reports/types'

const CrossTabPageSelect: FC<CrossTabPageProps> = (props) => {
  const { crossTabPage, pageData, setCrossTabPage } = props

  if (!pageData?.values) {
    return null
  }

  return (
    <Labeled label={`${pageData?.name}`}>
      <Select
        id="CrossTabPageSelect"
        data-testid={'CrossTabPageSelect'}
        fullWidth
        value={crossTabPage}
        name="Dataset Selector"
        placeholder="select "
        size="small"
        onChange={(event) => {
          setCrossTabPage(event.target.value)
        }}
        className="report-field"
      >
        {pageData.values.map((dataset: string) => (
          <MenuItem key={dataset} value={dataset}>
            {dataset.charAt(0).toUpperCase() + dataset.slice(1)}
          </MenuItem>
        ))}
      </Select>
    </Labeled>
  )
}

export default CrossTabPageSelect
