import { SxProps } from '@mui/material'

export const questionContentContainer = (secondary_color: string): SxProps => ({
  minHeight: { md: '60vh', xs: '50vh' },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 16px',
  backgroundColor: secondary_color,
  border: `2px solid`,
  borderRadius: '20px',
  flexDirection: 'column',
  gap: '10px',
  boxShadow:
    'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;',
})

export const textContent: SxProps = {
  color: '#000',
  lineHeight: 1.3,
  textAlign: 'center',
  fontWeight: 300,
  fontSize: {
    md: 18,
    sm: 14,
  },
  whiteSpace: 'pre-wrap',
  marginBottom: 0,
  '& *:not(:last-child)': {
    marginBottom: '5px',
  },
}

export const typoStyle = (color: string): SxProps => ({
  marginBottom: 2,
  color,
  fontSize: { md: '20px', xs: '16px' },
  fontWeight: 600,
  textAlign: 'center',
})

export const styleForLandscapeMainBox: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  '& > *': {
    flexBasis: 'calc(50% - 20px)',
  },
}
