import { useMediaQuery } from '@mui/material'
import { TableResizeObjectInterface } from 'src/components/types'

interface ScreenSettings {
  perPage: number
  rowsPerPageOptions: number[]
  calculateHeightsInights: string
  calculateHeightList: string
}
export const useScreenSettings = (
  tableResizeObject: TableResizeObjectInterface,
): ScreenSettings => {
  const isScreenBelow400 = useMediaQuery('(max-height:400px)')
  const isScreenAbove400 = useMediaQuery('(min-height: 401px)')
  const isScreenBelow600 = useMediaQuery('(max-height: 600px)')
  /* c8 ignore next 1 */
  const isScreenBetween400And600 = isScreenAbove400 && isScreenBelow600
  const {
    isScreenBelow400Value,
    isScreenBetween400And600Value,
    isScreenAbove600Value,
  } = tableResizeObject
  let perPage: number
  let rowsPerPageOptions: number[]
  let calculateHeightsInights: string
  let calculateHeightList: string
  /* c8 ignore next 25 */
  if (isScreenBelow400) {
    perPage = 2
    rowsPerPageOptions = [2, 5, 10]
    calculateHeightsInights = isScreenBelow400Value?.calculateHeightsInights
    calculateHeightList = isScreenBelow400Value?.calculateHeightList
  } else if (isScreenBetween400And600) {
    perPage = 5
    rowsPerPageOptions = [5, 10, 25]
    calculateHeightsInights =
      isScreenBetween400And600Value.calculateHeightsInights
    calculateHeightList = isScreenBetween400And600Value.calculateHeightList
  } else {
    perPage = 10
    rowsPerPageOptions = [5, 10, 50]
    calculateHeightsInights = isScreenAbove600Value.calculateHeightsInights
    calculateHeightList = isScreenAbove600Value.calculateHeightList
  }

  return {
    perPage,
    rowsPerPageOptions,
    calculateHeightsInights,
    calculateHeightList,
  }
}
