import { SxProps } from '@mui/system'
import { Mx8Theme } from '../../../layout'

export const projectTable: SxProps = {
  width: '100%',
  padding: '8px 10px 0',
  border: `1px solid ${Mx8Theme.palette.divider}`,
  backgroundColor: 'background.paper',
}

export const userTable: SxProps = {
  backgroundColor: 'background.paper',
  '& .MuiTableRow-head .MuiTableCell-root': {
    fontWeight: 600,
  },
  '& .MuiTableCell-root': {
    padding: '7px 20px',
  },
}
