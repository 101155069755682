import { Box, CardMedia, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { MediaModaProps } from '../../../types'
import {
  closeIconStyle,
  mediaModalContainer,
  textContainer,
  textStyle,
} from './style'
import { FC } from 'react'

const MediaModal: FC<MediaModaProps> = (props) => {
  const { setOpenModelUrl, openModelUrl } = props
  const handleClose = () =>
    setOpenModelUrl({
      playBackUrl: '',
      modelOpen: false,
      conditionCheck: '',
    })

  return (
    /* /* c8 ignore next 4 */
    <Modal open={openModelUrl.modelOpen} onClose={handleClose}>
      <Box sx={mediaModalContainer}>
        <Box sx={textContainer}>
          <Typography
            data-testid="modal-modal-title"
            variant="h6"
            component="h2"
            sx={textStyle}
          >
            {' '}
            {openModelUrl?.conditionCheck}
          </Typography>
          <CloseIcon
            data-testid="close-icone"
            onClick={handleClose}
            sx={closeIconStyle}
          />
        </Box>

        {openModelUrl?.conditionCheck?.includes('.mp4') ? (
          <iframe
            title="VideoPlayer"
            allow="encrypted-media"
            data-testid="iframe-component"
            src={openModelUrl.playBackUrl as string}
            style={{
              height: `calc(90vh - 260px)`,
              width: '100%',
            }}
            allowFullScreen={true}
          />
        ) : (
          <CardMedia
            component="img"
            image={openModelUrl.playBackUrl as string}
            height={`calc(95vh - 260px)`}
            data-testid="card-media-modal"
            alt="Card Image"
            sx={{ maxHeight: '55vh', objectFit: 'contain' }}
          />
        )}
      </Box>
    </Modal>
  )
}
export default MediaModal
