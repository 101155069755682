import ErrorIcon from '@mui/icons-material/Error'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import * as Sentry from '@sentry/react'
import { Dispatch, SetStateAction } from 'react'
export const downloadFromUrl = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const handleErrorResponse = (
  error: unknown,
  setDownloading: (downloading: boolean) => void,
  setReportDownloadDisabled: Dispatch<SetStateAction<boolean>> | undefined,
  setError: (error: boolean) => void,
  notify: (message: string, options?: object) => void,
) => {
  console.error(error)
  setDownloading(false)
  setError(true)
  if (setReportDownloadDisabled) {
    setReportDownloadDisabled(true)
  }
  Sentry.captureException(error)
  notify('Error downloading file' + error, { type: 'error' })
}

const renderErrorIcon = () => <ErrorIcon color="error" />

const renderDownloadIcon = () => <FileDownloadIcon data-testid="download" />

export const renderErrorAndDownloadIcon = (errState: boolean) =>
  errState ? renderErrorIcon() : renderDownloadIcon()
