import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { useGetList, useNotify, useRecordContext, useUpdate } from 'react-admin'
import { FC, useEffect, useState } from 'react'
import { Save } from '@mui/icons-material'
import {
  checkBoxContainer,
  loaderContainer,
  parentList,
  reportNameContainer,
  surveyNameContainer,
} from './style'

type ReportSurveys = {
  survey_id: number
  survey_name: string
  label: string
  weight: number
}
type UpdatedReportType = {
  id: number
  name: string
  surveys: ReportSurveys[]
  isSurveyAdded: boolean
  isSurveyRemoved: boolean
}

const ReportConfigure: FC = () => {
  const [updatedReport, setUpdatedReport] = useState<UpdatedReportType[]>()
  const [update, { isPending }] = useUpdate()
  const notify = useNotify()
  const record = useRecordContext()
  const survey = useGetList('surveys', { filter: { project_id: record?.id } })
  const reports = useGetList('reports', { filter: { project_id: record?.id } })

  const reportData = reports?.data ? [{ id: 0, name: '' }, ...reports.data] : []

  const itemWidth = survey?.data?.length
    ? `${Math.round(100 / (survey?.data?.length + 1))}%`
    : null

  const handleCheck = (
    reportId: number,
    surveyData: {
      id: number
      name: string
    },
    checked: boolean,
  ) => {
    const surveyTobeAdd = {
      survey_id: surveyData?.id,
      survey_name: surveyData?.name,
      label: surveyData.name,
      weight: 1,
    }

    const isSurveyPresentInRecord = (report: UpdatedReportType) =>
      report?.surveys?.find((sur) => sur.survey_id === surveyData.id)

    const surveysList = (report: UpdatedReportType) =>
      report?.surveys?.filter((survey) => survey?.survey_id !== surveyData.id)

    if (checked) {
      setUpdatedReport((prev) => {
        return prev?.map((report) => {
          if (report?.id === reportId) {
            return {
              ...report,
              surveys: [...report.surveys, surveyTobeAdd],
              isSurveyAdded: !isSurveyPresentInRecord(report),
              isSurveyRemoved: !!isSurveyPresentInRecord(report),
            }
          } else {
            return report
          }
        })
      })
    } else {
      setUpdatedReport((prev) => {
        return prev?.map((report) => {
          if (report?.id === reportId) {
            return {
              ...report,
              surveys: surveysList(report),
              isSurveyRemoved: !isSurveyPresentInRecord(report),
              isSurveyAdded: !!isSurveyPresentInRecord(report),
            }
          } else {
            return report
          }
        })
      })
    }
  }

  const handleSave = () => {
    updatedReport?.forEach((report) => {
      if (report.surveys?.length < 2) {
        const reportName = reportData.find(
          (rep) => report.id === rep.id && rep.id !== 0,
        )
        notify(`${reportName.name} should contain minimum two surveys`, {
          type: 'error',
        })
      } else if (report.isSurveyAdded || report.isSurveyRemoved) {
        update('reports', {
          id: report?.id,
          data: { surveys: report.surveys },
          previousData: reportData.find(
            (previousReport) => previousReport.id === report.id,
          ),
        })
      }
    })
  }

  useEffect(() => {
    if (reports?.data) {
      const selectedSurveys = reportData.map((report: UpdatedReportType) => ({
        id: report.id,
        name: report.name,
        surveys: report.surveys,
        isSurveyAdded: false,
        isSurveyRemoved: false,
      }))
      setUpdatedReport(selectedSurveys)
    }
  }, [reports?.data])

  if (!reports?.data || !survey.data)
    return (
      <Box sx={loaderContainer} data-testid="configure-report-modal-loader">
        <CircularProgress />
      </Box>
    )

  return (
    <Box sx={{ padding: '0 30px 20px' }}>
      <DialogTitle sx={{ paddingLeft: 0 }}>Configure Reports</DialogTitle>
      <List sx={{ overflow: 'auto' }}>
        {updatedReport?.map((report, idx) => (
          <ListItem sx={{ padding: 0 }} key={`report-${report.id}`}>
            <List sx={parentList(itemWidth, !!report?.id)}>
              {report?.id ? (
                <ListItem
                  sx={reportNameContainer(
                    !!report?.id,
                    idx === reportData.length - 1,
                  )}
                  key={`report-${report.name}`}
                >
                  <Typography fontWeight={600} sx={{ padding: '18px' }}>
                    {report.name}
                  </Typography>
                </ListItem>
              ) : (
                <ListItem
                  sx={{
                    padding: 0,
                    /* c8 ignore next 3 */
                    backgroundColor: report.id ? 'background.default' : null,
                  }}
                  key="empty-field"
                />
              )}
              {survey?.data?.map((sur) => {
                const isChecked = !!report?.surveys?.find(
                  (repSurvey: { survey_id: number }) =>
                    sur.id === repSurvey.survey_id,
                )
                return (
                  <>
                    {report.id !== 0 ? (
                      <ListItem
                        key={`survey-${sur.id}`}
                        sx={checkBoxContainer(idx === reportData.length - 1)}
                      >
                        <Checkbox
                          sx={{ display: 'block' }}
                          data-testid={`check-${report.id}-${sur.id}`}
                          onClick={() =>
                            handleCheck(report.id, sur, !isChecked)
                          }
                          checked={isChecked}
                        />
                      </ListItem>
                    ) : (
                      <ListItem
                        sx={surveyNameContainer}
                        key={`survey-${sur.name}`}
                      >
                        <Typography fontWeight={600} sx={{ padding: '18px' }}>
                          {sur.name}
                        </Typography>
                      </ListItem>
                    )}
                  </>
                )
              })}
            </List>
          </ListItem>
        ))}
      </List>
      <DialogActions sx={{ marginTop: '20px', padding: 0 }}>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={isPending}
          startIcon={<Save />}
          data-testid="save-reports"
        >
          Save
        </Button>
      </DialogActions>
    </Box>
  )
}

export default ReportConfigure
