export const avatarWithoutTooltipContainer = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  fontSize: 14,
}

export const avatarStyleWithoutTooltip = (color: string) => ({
  backgroundColor: color,
  marginRight: 1,
})

export const avatarContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}

export const avatarStyle = (color: string) => ({
  backgroundColor: color,
  height: 24,
  width: 24,
  padding: 2,
})
