import { Alert, CircularProgress, Grid2 } from '@mui/material'
import { FC, useState } from 'react'
import AnalysisContainer from './AnalysisContainer'
import SignificanceInput from './SignificanceInput'
import ConfidenceInput from './ConfidenceInput'
import { useGetList, useRecordContext } from 'ra-core'
const ReportAnalysis: FC = () => {
  const [validationForReportQuestion, setValidationForReportQuestion] =
    useState(false)
  const record = useRecordContext()
  const { data } = useGetList('report_questions', {
    filter: { id: record?.selected_questions, report_id: record?.id },
    sort: { field: 'question.line_number', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
  })
  if (!data) {
    return (
      <Grid2
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          minHeight: '200px',
        }}
      >
        <CircularProgress />
      </Grid2>
    )
  }
  return (
    <Grid2 container width="100%" spacing={2} data-testid="report-analysis">
      <Grid2 size={{ xs: 12, md: 5 }}>
        <SignificanceInput />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 5 }}>
        <ConfidenceInput />
      </Grid2>

      {
        /* c8 ignore next 6 */
        validationForReportQuestion && (
          <Alert severity="error">
            At least one 'Row' report style is required, and no more than one
            'Page' report style can be selected.
          </Alert>
        )
      }
      <AnalysisContainer
        reference="report_questions"
        title="Question"
        showOption={true}
        name="reporting_label"
        setValidationForReportQuestion={setValidationForReportQuestion}
      />
      <AnalysisContainer
        name="topic"
        reference="report_topics"
        title="Topic"
        isNoneOption
        setValidationForReportQuestion={setValidationForReportQuestion}
      />
    </Grid2>
  )
}

export default ReportAnalysis
