import { SxProps } from '@mui/system'

export const creditCardContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

export const breadCrumbContainer: SxProps = {
  marginBottom: 3,
  alignSelf: 'flex-start',
  gap: 1,
  cursor: 'pointer',
  textTransform: 'capitalize',
}

export const creditCardForm: SxProps = {
  padding: '30px',
  marginTop: '20px',
  backgroundColor: 'background.paper',
}

export const modalContainer: SxProps = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const cardContainer: SxProps = {
  maxWidth: '500px',
  width: { xs: '85%', sm: '50%' },
  padding: '1rem 3% 2rem',
  backgroundColor: 'background.default',
}

export const statusContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '12px',
}
