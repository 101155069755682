import { Auth0Client } from '@auth0/auth0-spa-js'
import * as Sentry from '@sentry/react'
import { Auth0AuthProvider, httpClient } from 'ra-auth-auth0'
import { AuthProvider, Options } from 'react-admin'
import ReactGA from 'react-ga4'

type Permissions = {
  user_id: number
  user_email: string
  is_superuser: boolean
}

interface AuthConfig {
  auth0Client: Auth0Client
  authProvider: AuthProvider
  httpClient: (
    url: string,
    options: Options | undefined,
  ) => Promise<{
    status: number
    headers: Headers
    body: string
    json: string
  }>
  getPermissions: () => Promise<Permissions>
}

let authInstance: AuthConfig | null = null
interface SessionStorageHandler {
  get(key: string): void
  set(key: string, value: unknown): void
  remove(key: string): void
  allKeys(): string[]
}
export const sessionStorageObj: SessionStorageHandler = {
  get(key) {
    if (key == null) {
      Sentry.captureMessage(`Key cannot be null`, 'info')
      console.error('Key cannot be null ')
      return null
    }
    const validkey = sessionStorage.getItem(key)
    return validkey ? JSON.parse(validkey) : null
  },

  set(key, value): void {
    sessionStorage.setItem(key, JSON.stringify(value))
  },

  remove(key): void {
    sessionStorage.removeItem(key)
  },

  // Optional
  allKeys: function () {
    return Object.keys(sessionStorage)
  },
}
export const getAuth = (): AuthConfig => {
  if (authInstance) {
    return authInstance
  }

  const auth0Client = new Auth0Client({
    domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
    cacheLocation: 'localstorage',
    authorizationParams: {
      audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
      redirect_uri: import.meta.env.VITE_LOGIN_REDIRECT_URL as string,
    },
  })

  const authProvider = Auth0AuthProvider(auth0Client, {
    loginRedirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URL as string,
    logoutRedirectUri: import.meta.env.VITE_LOGOUT_REDIRECT_URL as string,
  })

  /* c8 ignore next 77 */
  const getPermissions = async () => {
    const token = await getAuth().auth0Client.getTokenSilently()

    const api_protocol = import.meta.env.VITE_MX8_ADMIN_API_PROTOCOL ?? 'https'

    const response = await fetch(
      `${api_protocol}://${import.meta.env.VITE_MX8_ADMIN_API_DOMAIN}/v1/users/me`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    if (response.ok) {
      const data = await response.json()
      ReactGA.event({
        category: 'user id',
        action: 'user login',
        label: 'user login firdt time',
        value: data.id,
      })
      ReactGA.send({
        user_id: data.id,
        user_email: data?.email,
        is_superuser: data?.is_superuser,
      })
      return data
    } else {
      Sentry.captureMessage(`${response.statusText}`, 'info')
      console.log('something went wrong')
    }
  }

  authInstance = {
    auth0Client: auth0Client,
    authProvider: authProvider,
    httpClient: httpClient(auth0Client),
    getPermissions: getPermissions,
  }

  return getAuth()
}
