import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Button,
  Checkbox,
  Divider,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@mui/material'
import React, { useState } from 'react'
import { InputProps, useInput } from 'react-admin'
import { userTable } from '../../Edit/styles'
import { User } from '../../types'

const UserListInput: React.FC<InputProps> = (props: InputProps) => {
  const [newUserEmail, setNewUserEmail] = useState<string>('')
  const {
    field: { value, onChange },
  } = useInput(props)

  const handleAddUser = () => {
    if (newUserEmail.trim() === '') return

    const newUser: User = { email: newUserEmail, is_account_admin: false }
    onChange([...value, newUser])
  }

  const handleRemoveUser = (userToRemove: User) => {
    const updatedUsers = value.filter(
      (user: User) => user.email !== userToRemove.email,
    )
    onChange(updatedUsers)
  }

  const handleToggleAccountAdmin = (userToToggle: User) => {
    const updatedUsers: [User] = value.map((user: User) => {
      return {
        ...user,
        is_account_admin:
          user.email === userToToggle.email
            ? !user.is_account_admin
            : user.is_account_admin,
      }
    })
    onChange(updatedUsers)
  }

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <Divider />
        <TextField
          label="Add user"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ flex: 1, marginRight: '1rem' }}
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddUser}
          data-testid="add-user"
        >
          Add User
        </Button>
      </Box>
      <Table size="small" sx={userTable}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Can administer account?</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {value && value.length > 0 ? (
            value.map((user: User, index: number) => (
              <TableRow key={user.email}>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Checkbox
                    onChange={() => handleToggleAccountAdmin(user)}
                    checked={user.is_account_admin}
                    size="small"
                    data-testid={`toggle-account-admin-${index}`}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleRemoveUser(user)}
                    size="small"
                    startIcon={<DeleteIcon />}
                    color="warning"
                    data-testid="remove-user"
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No users added
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  )
}

export default UserListInput
