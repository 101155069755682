import { SxProps } from '@mui/material'
import { Mx8Theme } from '../layout'
import { TableResizeObjectInterface } from './types'

export const CreateButtonStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: 1,
  fontSize: '15px',
  '&  .MuiButton-text': {
    fontSize: '13px',
  },
}

export const CommonSaveCancelButton = {
  '& .MuiToolbar-gutters .RaToolbar-defaultToolbar': {
    position: 'fixed',
    right: '1.2%',
    gap: 2,
  },
  '& .MuiToolbar-gutters': {
    position: 'fixed',
    top: '93%',
    width: '100%',
  },
}

export const CustomFormActionContainer = (isDialog: boolean): SxProps => ({
  zIndex: '100 !important',
  justifyContent: 'end',
  gap: '15px',
  ...(!isDialog
    ? {
        position: 'fixed',
        right: 0,
        left: 0,
        bottom: 0,
        boxShadow: 'none',
        backgroundColor: 'background.paper',
        borderTop: `1px solid ${Mx8Theme.palette.divider}`,
      }
    : {
        backgroundColor: 'divider',
      }),
})

export const CommonNameText = {
  fontSize: '13px',
  fontWeight: '500',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  width: '98%',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}

export const CommonInputStyle = {
  ' & .MuiOutlinedInput-input': { fontSize: 14, padding: '7px 0px 7px 12px' },
  '& .MuiFormHelperText-root': { display: 'none' },
  '& .MuiFormLabel-root ': { fontSize: 13 },
}

export const TableResizeObject: TableResizeObjectInterface = {
  isScreenBelow400Value: {
    calculateHeightsInights: `calc(155vh - 208px)`,
    calculateHeightList: `calc(82.2vh - 155px)`,
  },
  isScreenBetween400And600Value: {
    calculateHeightsInights: `calc(117vh - 208px)`,
    calculateHeightList: `calc(62.1vh - 155px)`,
  },
  isScreenAbove600Value: {
    calculateHeightsInights: `calc(96.4vh - 208px)`,
    calculateHeightList: `calc(50vh - 160px)`,
  },
}

export const tableColumn = (height?: string): SxProps => ({
  '& .RaDatagrid-tableWrapper': {
    ...(height ? { height, overflow: 'auto' } : {}),
    '& .MuiTableCell-root': {
      '&:not(:nth-of-type(1),:nth-of-type(2))': {
        textAlign: 'center',
      },
    },
  },
})
