import React, { useEffect, useState, SetStateAction } from 'react'
import { Identifier, useNotify } from 'react-admin'
import { SurveyLinkButtonProps } from '../../../types'
import {
  Button,
  CircularProgress,
  Grid2,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Mx8Theme } from '../../../../../layout'
import { CopyAll, QuestionAnswer } from '@mui/icons-material'
import { getAuth } from '../../../../../Providers/AuthProvider'
import * as Sentry from '@sentry/react'
export const fetchData = async (
  surveyLinktest?: string,
  setData: (value: SetStateAction<null | string>) => void,
  setLoading?: (value: SetStateAction<boolean>) => void,
  setError?: (value: SetStateAction<null | string>) => void,
  firstQuestion?: (() => string) | undefined,
  source?: string | undefined,
  record_id?: Identifier | undefined,
) => {
  /* c8 ignore next 4 */
  const url =
    surveyLinktest === 'test-survey'
      ? `${import.meta.env.VITE_MX8_SURVEY_PROTOCOL ?? 'https'}://${import.meta.env.VITE_MX8_ADMIN_API_DOMAIN}/v1/${source}/${record_id}`
      : `${import.meta.env.VITE_MX8_SURVEY_PROTOCOL ?? 'https'}://${import.meta.env.VITE_MX8_ADMIN_API_DOMAIN}/v1/${source}/${record_id}?first_reporting_id=${firstQuestion?.()}`

  try {
    setLoading(true)
    const AUTH_TOKEN = await getAuth().auth0Client.getTokenSilently()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) throw new Error('Network response was not ok')
    const data = await response.json()
    setData(data)
    setLoading(false)
  } catch (error) {
    Sentry.captureException(error)

    setError(error)
  } finally {
    setLoading(false)
  }
}

/* c8 ignore next 108 */
const SurveyLinkButton: React.FC<SurveyLinkButtonProps> = (props) => {
  const notify = useNotify()
  const {
    source,
    record_id,
    disabled,
    language,
    firstQuestion,
    surveyLinktest,
  } = props

  const [data, setData] = useState<null | { url?: string }>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<null | Error>(null)

  useEffect(() => {
    fetchData(
      surveyLinktest,
      setData,
      setLoading,
      setError,
      firstQuestion,
      source,
      record_id,
    )
  }, [firstQuestion])

  const handleCopy = () => {
    if (data?.url) {
      navigator.clipboard.writeText(data.url)
      notify('URL copied to clipboard. Generating new one.', {
        type: 'success',
      })
      fetchData(
        surveyLinktest,
        setData,
        setLoading,
        setError,
        firstQuestion,
        source,
        record_id,
      )
    }
  }

  if (error) {
    notify('Error fetching test link', {
      type: 'error',
      undoable: false,
      autoHideDuration: null,
    })
  }

  const isDisabled = disabled || loading
  return (
    <Grid2
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      sx={{ width: '100%', marginTop: -1 }}
    >
      <Grid2 size="auto">
        <Tooltip title="Open test link in new tab" placement="top">
          <Button
            size="small"
            startIcon={
              <QuestionAnswer
                sx={{
                  color: isDisabled
                    ? Mx8Theme.palette.divider
                    : Mx8Theme.palette.primary.main,
                }}
              />
            }
            sx={{ color: Mx8Theme.palette.primary.main }}
            disabled={isDisabled}
            target="_blank"
            rel="noopener noreferrer"
            href={data?.url}
            key={language}
          >
            {language}
          </Button>
        </Tooltip>
      </Grid2>
      {loading ? (
        <Grid2 size="auto">
          <CircularProgress size={20} />
        </Grid2>
      ) : (
        <Grid2 size="auto" alignSelf="end">
          <Tooltip title="Copy URL to clipboard" placement="top">
            <IconButton
              size="small"
              onClick={handleCopy}
              sx={{ color: Mx8Theme.palette.primary.main }}
              disabled={isDisabled}
            >
              <CopyAll />
            </IconButton>
          </Tooltip>
        </Grid2>
      )}
    </Grid2>
  )
}

export default SurveyLinkButton
