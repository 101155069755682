import {
  FormDataConsumer,
  required,
  useGetList,
  useRecordContext,
} from 'ra-core'
import { FC } from 'react'
import {
  ArrayInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'
import { SurveyData } from '../types'
import { filterSelectedSurveys } from '../utils'

const ReportSurveyEdit: FC = () => {
  const record = useRecordContext()
  const { data } = useGetList('surveys', {
    filter: { project_id: record?.project_id },
  })

  return (
    <ArrayInput source="surveys" fullWidth>
      <SimpleFormIterator
        fullWidth
        disableReordering
        inline
        sx={{
          gap: '20px',
          '& .RaSimpleFormIterator-line': {
            paddingTop: '24px',
            paddingBottom: '.5rem',
            borderBottom: '1px solid #DBD2C8 !important',
          },
          '& .RaSimpleFormIterator-buttons': {
            paddingTop: '.5rem',
          },
        }}
      >
        <FormDataConsumer>
          {({ formData, scopedFormData }) => {
            return (
              <>
                <SelectInput
                  source="survey_id"
                  label="Surveys"
                  id="surveys"
                  data-testid={`survey-input-edit-${formData.project_id}`}
                  validate={required()}
                  sx={{ flexBasis: '38%' }}
                  choices={filterSelectedSurveys(
                    scopedFormData?.survey_id,
                    formData.surveys,
                    data as SurveyData,
                  )}
                />
                <NumberInput
                  source="weight"
                  data-testid={'edit-weight-input'}
                  defaultValue={1}
                  sx={{ flexBasis: '20%' }}
                />
                <TextInput
                  id="label"
                  label="Label"
                  source="label"
                  data-testid={'edit-lable-input'}
                  sx={{ flexBasis: '38%' }}
                />
              </>
            )
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default ReportSurveyEdit
