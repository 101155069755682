import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import Portrait from './components/Portrait'
import Landscape from './components/Landscape'
import {
  chatBodyContainer,
  chatBodyWrapper,
  parentContainer,
  previewContainer,
  previewLabel,
} from './style'
import { ThemeType } from '../../../../types'

const ChatPreview: FC<{ customTheme: ThemeType }> = ({ customTheme }) => {
  const isLandscapeMode = customTheme?.orientation === 'landscape'
  return (
    <Box width="100%" sx={previewContainer}>
      <Typography sx={previewLabel}>Preview</Typography>
      <Box
        sx={parentContainer(isLandscapeMode)}
        data-testid={`${customTheme?.orientation}-preview`}
      >
        <Box
          sx={chatBodyContainer(customTheme?.background_image, isLandscapeMode)}
        >
          <Box sx={chatBodyWrapper(isLandscapeMode)}>
            {isLandscapeMode ? (
              <Landscape customTheme={customTheme} />
            ) : (
              <Portrait customTheme={customTheme} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ChatPreview
