import { Mx8Theme } from '../../../../layout'

export const barContainer = {
  borderLeft: `1px solid ${Mx8Theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
}

export const barStyle = (width: number, firstColor: string) => ({
  position: 'absolute',
  height: 18,
  top: '5px',
  width: `${width}%`,
  backgroundColor: firstColor,
})

export const annotationStyle = (width: number) => ({
  position: 'absolute',
  height: 4,
  top: 0,
  left: 1,
  fontSize: 11,
  color: 'white',
  zIndex: 1,
  width: `${width}%`,
})

export const errorBarStartStyle = (width: number, secondColor: string) => ({
  position: 'absolute',
  height: 6,
  top: '11px',
  left: `${width}%`,
  width: '2px',
  backgroundColor: secondColor,
})

export const errorBarStyle = (
  left: number,
  width: number,
  secondColor: string,
) => ({
  position: 'absolute',
  height: 2,
  top: '13px',
  left: `${left}%`,
  width: `${width}%`,
  backgroundColor: secondColor,
})

export const errorBarEnd = (left: number, secondColor: string) => ({
  position: 'absolute',
  height: 6,
  top: '11px',
  left: `${left}%`,
  width: '2px',
  backgroundColor: secondColor,
})

export const textContainer = (left: number) => ({
  position: 'absolute',
  lineHeight: '24px',
  marginLeft: '2px',
  display: 'flex',
  justifyContent: 'center',
  top: '2px',
  left: `${left}%`,
})
