import { Stack, Box, Select, MenuItem } from '@mui/material'
import { LineChart } from '@mui/x-charts'
import { useEffect, useRef } from 'react'
import { lineChartGridContainer } from './styles'
import { LineChartProps } from '../../../../types'

export const chartReponsiveWidth = (chartWidth: number | undefined) => {
  if (chartWidth) {
    if (chartWidth < 700) {
      return 1000
    } else if (chartWidth > 1700) {
      return 1700
    } else {
      return chartWidth
    }
  }
}
const LineChartGrid = ({
  currentColumns,
  handleChange,
  linesColors,
  slotsMark,
  mainSeries,
  chartData,
  rows,
  setChartWidth,
  chartWidth,
}: LineChartProps) => {
  const divRef = useRef<HTMLDivElement | null>(null)

  const updateWidth = () => {
    if (divRef.current) {
      setChartWidth(divRef.current?.getBoundingClientRect().width)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    updateWidth()
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return (
    <Stack ref={divRef} sx={lineChartGridContainer}>
      <Box sx={{ width: 300 }}>
        <Select
          data-testid={`specific-columns-dropDown`}
          label={'columns'}
          size="small"
          value={currentColumns}
          sx={{ width: '100%' }}
          onChange={handleChange}
          defaultValue="Gender"
        >
          {chartData?.map((item, index) => {
            return (
              <MenuItem key={index + 1} value={item.label}>
                {item.label}
              </MenuItem>
            )
          })}
        </Select>
      </Box>
      <Box>
        <LineChart
          xAxis={[
            {
              data: rows?.map((item: { id: string | number }) => item.id),
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: {
                vertical: 'top',
                horizontal: 'right',
              },
              itemMarkWidth: 12,
              itemMarkHeight: 10,
              markGap: 5,
              itemGap: 8,
              padding: 49,
            },
            mark: {
              display: 'none',
            },
          }}
          colors={linesColors}
          slots={{
            mark: slotsMark,
          }}
          margin={{ right: 220 }}
          series={mainSeries}
          height={400}
          width={chartReponsiveWidth(chartWidth)}
        ></LineChart>
      </Box>
    </Stack>
  )
}

export default LineChartGrid
