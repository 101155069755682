export const switchCountryData = [
  {
    country_N: 'US',
    postal_code: 'ZIP code',
    locality: 'City',
    administrative_district_level_1: 'State',
  },
  {
    country_N: 'CA',
    postal_code: 'Postal code',
    locality: 'City 1',
    administrative_district_level_1: 'Province',
  },
  {
    country_N: 'AU',
    postal_code: 'Postal code 1',
    locality: 'Suburb',
    administrative_district_level_1: 'State 1',
  },
  {
    country_N: 'UK',
    postal_code: 'Postal code 2',
    locality: 'Town',
    administrative_district_level_1: 'N/A',
  },
  {
    country_N: 'IE',
    postal_code: 'Eircode',
    locality: 'Town/city',
    administrative_district_level_1: 'County',
  },
  {
    country_N: 'FR',
    postal_code: 'Postcode',
    locality: 'City 2',
    administrative_district_level_1: 'N/A',
  },
  {
    country_N: 'ES',
    postal_code: 'Postal code 3',
    locality: 'Locality',
    administrative_district_level_1: 'Province 1',
  },
  {
    country_N: 'null',
    postal_code: 'code',
    locality: 'City 3',
    administrative_district_level_1: 'State 1',
  },
]
